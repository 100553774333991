import React, {useState, useEffect} from "react";
import UrlService from "../../../Services/UrlService";
import axios from "axios";
import coverPreview from "../../../img/bookPreloader.svg"
import Preview from "../../Preview/Preview";
import stripHtml, { authorName } from "../../../Functions/Functions";
import Button from "../../Button/Button";
import vinil from "../../../img/vinil_blue.png";
import AdultMask from "../../AdultMask/AdultMask";
import Badges from "../../Badges/Badges";

function ShowCaseAudiobook(props){

    const urlService = new UrlService();
    
    const [apiRequestResult, setApiRequestResult] = useState(false);
    const [selectedBook, setSelectedBook] = useState(null);
    const [bookBadge, setBookBadge] = useState(null);

    useEffect(() => {
        axios.get(
            urlService.getApiURL(), {
            params: {
                sId: props?.content?.shelfId,
                type: "shelf"
            }
        })
        .then((data) => {
            setApiRequestResult(true);
            const responseData = data?.data?.data;
            if (!responseData || !responseData[0]?.BOOKS?.length) return false            
            const shelfData = data?.data?.data[0];
            shelfData.BOOKS = shelfData.BOOKS.sort((a, b) => 0.5 - Math.random());
            setSelectedBook(shelfData.BOOKS[0]);
            setBookBadge(shelfData.TITLE);
        });
        // eslint-disable-next-line
    }, []);

    if (apiRequestResult && !selectedBook){
        return null;
    }    

    return (
        <div className="showcaseRow sR-audiobook sR-book-wrapper">
            <div className="s-bg">
                <div className="content">
                    <div className="content-cover">
                        {selectedBook ? <img className="animate__animated animate__fadeIn" src={selectedBook?.COVER} alt={selectedBook?.TITLE}/> : <img className="coverPreview" src={coverPreview} alt=""/>}
                        <Badges bookData={selectedBook} big={true} />
                        {selectedBook ? (
                            <div className="animate__animated animate__fadeIn content-cover-vinil">
                                <img alt={selectedBook.TITLE} className="animate__animated animate__fadeIn" src={vinil} /> 
                            </div>
                        ) : null}
                        <AdultMask adultRating={selectedBook?.ADULT} />
                    </div>
                    <div className="content-data">
                        {bookBadge ? (
                            <div className="badge">{bookBadge}</div>
                        ) : null}
                        <div className="content-title">
                            {selectedBook ? selectedBook.TITLE : <Preview type={"sR-title"} />}
                        </div>
                        <div className="content-author">
                            {selectedBook ? authorName(selectedBook.AUTHORS) : <Preview type={"sR-author"} />}
                        </div>
                        <div className="content-categories">
                            {selectedBook ? (
                                selectedBook.CATEGORIES.map((el, key) => (
                                    <div key={key} className="content-categories-item">{el}</div>
                                ))
                            ) : <Preview type={"sR-author"} /> }
                        </div>
                        <div className="content-annotation">
                            {selectedBook ? stripHtml(selectedBook.ANNOTATION) : <Preview type={"sR-author"} />}
                        </div>
                        <div className="content-buttonsArea">
                            <Button text={"Подробнее"} action={"url"} href={"/book/"+selectedBook?.ID} buttonStyle={"thin bw w-144"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowCaseAudiobook;