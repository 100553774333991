import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { AppContext } from "../../Hooks/appContext";
import SubscriptionService from "../../Services/SubscriptionService";
import catAll from "../../img/new/cat-3.svg"
import catBooks from "../../img/new/cat-1.svg"
// import catAudio from "../../img/new/cat-2.svg"
import Button from "../Button/Button";
import "./SubsGrid.css"

function SubsGrid(){

    const {userSubscriptionsCtx, userIsBeeCtx } = useContext(AppContext);
    const [subscriptionData, setSubscriptionData] = useState(null);
    // const [audioDaily, setAudioDaily] = useState(false);
    const [allDaily, setAllDaily] = useState(false);
    const [booksDaily, setBooksDaily] = useState(false);

    const subscriptionService = new SubscriptionService();

    useEffect(() => {
        const subscriptions = subscriptionService.getSubscriptions();
        setSubscriptionData({
            all: subscriptions.filter(sub => sub.name === "all")[0],
            books: subscriptions.filter(sub => sub.name === "books")[0],
            audio: subscriptions.filter(sub => sub.name === "audio")[0]
        });
        // eslint-disable-next-line
    }, []);

    useLayoutEffect(() => {
        if (userSubscriptionsCtx){
            changeSubscriptionPeriod("all", false);
            changeSubscriptionPeriod("audio", false);
            changeSubscriptionPeriod("books", false);
        }
    // eslint-disable-next-line
    }, [userSubscriptionsCtx]);

    if (!subscriptionData){
        return null;
    }

    function getSubscriptionText(subsType){
        if (!userSubscriptionsCtx){
            return null;
        }
        return (
            <div className="badge transparent">
                {(userSubscriptionsCtx[subsType]?.TNB || userSubscriptionsCtx[subsType]?.softTnbAndFree) ? (
                    <>бесплатно до</>
                ) : userSubscriptionsCtx[subsType]?.halfClosed ? (
                    <>активна до</>
                ) : (
                    <>следующее списание</>
                )} {userSubscriptionsCtx[subsType]?.expired}
            </div>
        )
    }

    function changeSubscriptionPeriod(type, value){
        switch(type){
            case "all":
                setAllDaily(userSubscriptionsCtx?.all?.isActive ? Number(userSubscriptionsCtx?.all?.plan) === 1 : value);
                break;
            case "books":
                setBooksDaily(userSubscriptionsCtx?.books?.isActive ? Number(userSubscriptionsCtx?.books?.plan) === 1 : value);
                break;
            case "audio":
                // setAudioDaily(userSubscriptionsCtx?.audio?.isActive ? Number(userSubscriptionsCtx?.audio?.plan) === 1 : value);
                break; 
            default:
                break;
        }
    }

    return (
        <div className="subsGrid">
            <div className="subsGrid-item">
                <div className="topContent">
                    {userSubscriptionsCtx?.all?.isActive ? (
                        getSubscriptionText(subscriptionData?.all?.name)
                    ) : (
                        <div className="badge">
                            Выгодно
                        </div>
                    )}
                    <div className="title">
                        {subscriptionData?.all?.title}
                    </div>
                    <div className="description">
                    {subscriptionData?.all?.subTitle.replace('<br/>', '\n')}
                    </div>
                    <div className="catImage">
                        <img src={catAll} alt={subscriptionData?.all?.subTitle} />
                    </div>
                </div>
                <div className="bottomContent">
                    <div className={`radioButtons ${userSubscriptionsCtx?.all?.softTnbAndFree ? "hidden" : ""}`}>
                        { userIsBeeCtx ? (
                            <div className={`radioButtonsItem${allDaily ? " active" : ""}`} onClick={() => changeSubscriptionPeriod("all", true) }>
                                <div className="radioButton">
                                    <div className="circle"></div>
                                </div>
                                <div className="radioButtonDecription">
                                    <div className="firstLine">
                                        ежедневно
                                    </div>
                                    <div className="secondLine">
                                        для абонентов билайн
                                    </div>
                                </div>
                                <div className="radioButtonPrice">
                                    <div className="badge">
                                        первые 7 дней бесплатно
                                    </div>
                                    <div className="price">
                                        {subscriptionData?.all?.prices?.d} ₽
                                    </div>
                                </div>
                            </div>
                        ) : null }
                        <div className={`radioButtonsItem${!allDaily ? " active" : ""}`} onClick={() => changeSubscriptionPeriod("all", false)}>
                            <div className="radioButton">
                                <div className="circle"></div>
                            </div>
                            <div className="radioButtonDecription">
                                <div className="firstLine">
                                    ежемесячно
                                </div>
                                <div className="secondLine">
                                    для всех
                                </div>
                            </div>
                            <div className="radioButtonPrice">
                                {!userSubscriptionsCtx?.all?.isActive ? (
                                    <div className="badge">
                                        один месяц бесплатно
                                    </div>
                                ) : null }
                                <div className="price">
                                    {subscriptionData?.all?.prices?.m} ₽
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="buttonArea">
                        {userSubscriptionsCtx?.all?.isActive ? (
                            <Button text="подключено" buttonStyle="disabled white ptb-15 w-300 bp-nb" />
                        ) : (
                            <Button action="modal" modalSrc="subscriptionPanel" text={`подключить за ${allDaily ? subscriptionData?.all?.prices?.d : (userSubscriptionsCtx?.extra?.tarifUp ? subscriptionData?.all?.prices?.up : subscriptionData?.all?.prices?.m)} ₽`} buttonStyle={`white ptb-15 w-300 bp-nb`} additData={{type: subscriptionData?.all?.name, dailyPlan:allDaily}} customGoal={"subscribe_all"} />
                        ) }
                    </div>
                    {(userSubscriptionsCtx?.extra?.tarifUp && !allDaily) ? (
                        <div className="tarifupWrapper">
                            Специальная цена на Вашем тарифе Up
                        </div>
                    ) : null}                    
                </div>
            </div>
            <div className="subsGrid-item">
                <div className="topContent">
                    {userSubscriptionsCtx?.books?.isActive ? ( getSubscriptionText(subscriptionData?.books?.name) ) : null}
                    <div className="title">
                        {subscriptionData?.books?.title}
                    </div>
                    <div className="description">
                    {subscriptionData?.books?.subTitle.replace('<br/>', '\n')}
                    </div>
                    <div className="catImage">
                        <img src={catBooks} alt={subscriptionData?.books?.subTitle} />
                    </div>
                </div>
                <div className="bottomContent">
                    <div className={`radioButtons ${userSubscriptionsCtx?.books?.softTnbAndFree ? "hidden" : ""}`}>
                        { userIsBeeCtx ? (
                            <div className={`radioButtonsItem${booksDaily ? " active" : ""}`} onClick={() => changeSubscriptionPeriod("books", true)}>
                                <div className="radioButton">
                                    <div className="circle"></div>
                                </div>
                                <div className="radioButtonDecription">
                                    <div className="firstLine">
                                        ежедневно
                                    </div>
                                    <div className="secondLine">
                                        для абонентов билайн
                                    </div>
                                </div>
                                <div className="radioButtonPrice">
                                    {!(userSubscriptionsCtx?.extra?.tarifEmployees && userSubscriptionsCtx?.books?.plan === 1) ? (
                                        <>
                                            <div className="badge">
                                                первые 7 дней бесплатно
                                            </div>
                                            <div className="price">
                                                {subscriptionData?.books?.prices?.d} ₽
                                            </div>
                                        </>
                                    ) : (
                                        <div className="price">
                                            0 ₽
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : null }
                        <div className={`radioButtonsItem${!booksDaily ? " active" : ""}`} onClick={() => changeSubscriptionPeriod("books", false)}>
                            <div className="radioButton">
                                    <div className="circle"></div>
                                </div>
                                <div className="radioButtonDecription">
                                    <div className="firstLine">
                                        ежемесячно
                                    </div>
                                    <div className="secondLine">
                                        для всех
                                    </div>
                                </div>
                                <div className="radioButtonPrice">
                                    {!(userSubscriptionsCtx?.extra?.tarifEmployees && userSubscriptionsCtx?.books?.plan === 2) ? (
                                        <>
                                        <div className="badge">
                                            первые 14 дней бесплатно
                                        </div>
                                        <div className="price">
                                            {subscriptionData?.books?.prices?.m} ₽
                                        </div>                                        
                                        </>
                                    ) : (
                                        <div className="price">
                                            0 ₽
                                        </div>
                                    )}
                                </div>
                        </div>                        
                    </div>
                    <div className="buttonArea">
                        {userSubscriptionsCtx?.books?.isActive ? (
                            <Button text="подключено" buttonStyle="disabled white ptb-15 w-300 bp-nb" />
                        ) : (
                            <Button action="modal" modalSrc="subscriptionPanel" text={`подключить за ${booksDaily ? subscriptionData?.books?.prices?.d : subscriptionData?.books?.prices?.m} ₽`} buttonStyle="white ptb-15 w-300 bp-nb" additData={{type: subscriptionData?.books?.name, dailyPlan:booksDaily}} customGoal={"subscribe_books"} />
                        ) }
                    </div>
                </div>
            </div>
            {/* <div className="subsGrid-item">
                <div className="topContent">
                    {userSubscriptionsCtx?.audio?.isActive ? ( getSubscriptionText(subscriptionData?.audio?.name) ) : null}
                    <div className="title">
                        {subscriptionData?.audio?.title}
                    </div>
                    <div className="description">
                    {subscriptionData?.audio?.subTitle}
                    </div>
                    <div className="catImage">
                        <img src={catAudio} alt={subscriptionData?.audio?.subTitle} />
                    </div>
                </div>
                <div className="bottomContent">
                    <div className="radioButtons">
                        { userIsBeeCtx ? (
                            <div className={`radioButtonsItem${audioDaily ? " active" : ""}`} onClick={() => changeSubscriptionPeriod("audio", true)}>
                                <div className="radioButton">
                                    <div className="circle"></div>
                                </div>
                                <div className="radioButtonDecription">
                                    <div className="firstLine">
                                        Ежедневно
                                    </div>
                                    <div className="secondLine">
                                        <div>
                                            для абонентов билайн</div>
                                        <div>
                                            {subscriptionData?.audio?.prices?.d} ₽
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null }
                        <div className={`radioButtonsItem${!audioDaily ? " active" : ""}`} onClick={() => changeSubscriptionPeriod("audio", false)}>
                            <div className="radioButton">
                                <div className="circle"></div>
                            </div>
                            <div className="radioButtonDecription">
                                <div className="firstLine">
                                    <div>Ежемесячно</div>
                                    <div className="badge">
                                        -17%
                                    </div>
                                </div>
                                <div className="secondLine">
                                    <div>
                                        для всех</div>
                                    <div>
                                        <span>{subscriptionData?.audio?.prices?.d*30} ₽</span> {subscriptionData?.audio?.prices?.m} ₽
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="buttonArea">
                        {userSubscriptionsCtx?.audio?.isActive ? (
                            <Button text="Подключено" buttonStyle="disabled white ptb-15 w-fw bp-nb" />
                        ) : (
                            <Button action="modal" modalSrc="subscriptionPanel" text="Подключить" buttonStyle="withPrice white ptb-10 w-fw bp-nb" additData={{price: audioDaily ? subscriptionData?.audio?.prices?.d : subscriptionData?.audio?.prices?.m, type: subscriptionData?.audio?.name, dailyPlan:audioDaily}} customGoal={"subscribe_audio"} />
                        ) }
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default SubsGrid;